'use client';

import React from 'react'; // Add this line to import React
import { useRouter } from 'next/navigation'; // Import useRouter from next/router
import { ArrowRight } from 'lucide-react';
import Image from 'next/image';
export default function NotFound() {
  const router = useRouter(); // Initialize the router

  const handleHomeRedirect = () => {
    router.push('/'); // Redirect to the home page
  };
  return <>

            <section className="min-h-screen flex items-center justify-center">
                <div className="flex flex-row items-center">
                    <Image src="/assets/404.png" // Path to your 404 image asset
        alt="404 Not Found" width={400} // Adjust width as needed
        height={300} // Adjust height as needed
        className="mb-4" data-sentry-element="Image" data-sentry-source-file="not-found.tsx" />
                    <div className="flex flex-col ml-10 mr-40">
                        <span className='text-6xl font-inter-bold text-oxford mb-4'>Oops!</span>
                        <span className='text-4xl font-inter text-oxford mb-6'>We could not find the page <br></br> you are looking for.</span>
                        <span onClick={handleHomeRedirect} className='text-2xl font-inter text-oxford mb-2 flex items-center hover:underline hover:underline-offset-8 hover:cursor-pointer'>Return to safety <ArrowRight className="ml-2" data-sentry-element="ArrowRight" data-sentry-source-file="not-found.tsx" /></span>

                    </div>
                </div>
            </section>
        </>;
}